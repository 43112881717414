<template>
  <div class="box">
    <div class="hd">防伪码生成统计</div>
    <div class="bd">
      <div id="genderPercent" style="width:100%; height:100%"/>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
/* eslint-disable */
import * as echarts from 'echarts'
export default {
  name: 'LeftChart2',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawGenderChart()
      // this.drawAgeChart()
    },
    drawGenderChart() {
      var myChart = echarts.init(document.getElementById("genderPercent"), 'dark');
      var option
      option = {
          backgroundColor:'',
          grid:{
            x:55,
            y:5,
            x2:5,
            y2:25,
            borderWidth:1
          },
          xAxis: {
              type: 'category',
              boundaryGap: false
          },
          yAxis: {
              type: 'value',
              boundaryGap: [0, '30%']
          },
          visualMap: {
              type: 'piecewise',
              show: false,
              dimension: 0,
              seriesIndex: 0,
              pieces: [{
                  gt: 1,
                  lt: 3,
                  color: 'rgba(0, 0, 180, 0.4)'
              }, {
                  gt: 5,
                  lt: 7,
                  color: 'rgba(0, 0, 180, 0.4)'
              }]
          },
          series: [
              {
                  type: 'line',
                  smooth: 0.6,
                  symbol: 'none',
                  lineStyle: {
                      color: '#5470C6',
                      width: 5
                  },
                  markLine: {
                      symbol: ['none', 'none'],
                      label: {show: false},
                      data: [
                          {xAxis: 1},
                          {xAxis: 3},
                          {xAxis: 5},
                          {xAxis: 7}
                      ]
                  },
                  areaStyle: {},
                  data: [
                      ['2019-10-10', 200],
                      ['2019-10-11', 560],
                      ['2019-10-12', 750],
                      ['2019-10-13', 580],
                      ['2019-10-14', 250],
                      ['2019-10-15', 300],
                      ['2019-10-16', 450],
                      ['2019-10-17', 300],
                      ['2019-10-18', 100]
                  ]
              }
          ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    },
    drawAgeChart () {
      var myChart = echarts.init(document.getElementById("agePercent"), 'dark');
      var option
      option = {
        backgroundColor:'',
        title: {
            text: '各部门用电情况',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: '各年龄常住人员',
                type: 'pie',
                radius: '65%',
                data: [
                    {value: 104, name: '居民区'},
                    {value: 735, name: '制鞋区'},
                    {value: 580, name: '科技园'},
                    {value: 484, name: '政府部门'},
                ],
                label:{
                  show: true,
                  position: 'inside' 
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}

.box-progression-stats {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  ::v-deep .content {
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
    flex: 3;
    .item {
      width: 100%
    }
  }

  .widget {
    flex: 2
  }
}
</style>
