<template>
  <div class="box">
    <div class="hd">热点新闻</div>
    <div class="bd">
      <ul>
        <li class="list-text"><a href="#"><h3>扫码溯源，看看皮鞋的皮真不真</h3></a></li>
        <li class="list-text"><a href="http://www.zj.gov.cn/art/2020/6/23/art_1228932745_48637601.html"><h3>钱江晚报：扫码查溯源，“浙冷链”正式上线，全国首创</h3></a></li>
        <li class="list-text"><a href="http://www.cicn.com.cn/2020-07/09/cms128603article.shtml"><h3>冷冻食品全程流向一目了然</h3></a></li>
        <li class="list-text"><a href="http://news.66wz.com/system/2020/06/24/105286488.shtml"><h3>对冷链食品不放心？今后可以扫码溯源！</h3></a></li>
        <li class="list-text"><a href="http://news.66wz.com/system/2020/06/24/105286488.shtml"><h3>溯源产业迎来快速发展期</h3></a></li>
      </ul>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LeftChart4',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawAccess()
    },
    drawAccess(){
      var myChart = echarts.init(document.getElementById("access"), 'dark');
      var option
      option = {
          backgroundColor:'',
          xAxis: {
              type: 'category',
              boundaryGap: false
          },
          yAxis: {
              type: 'value',
              boundaryGap: [0, '30%']
          },
          visualMap: {
              type: 'piecewise',
              show: false,
              dimension: 0,
              seriesIndex: 0,
              pieces: [{
                  gt: 1,
                  lt: 3,
                  color: 'rgba(0, 0, 180, 0.4)'
              }, {
                  gt: 5,
                  lt: 7,
                  color: 'rgba(0, 0, 180, 0.4)'
              }]
          },
          series: [
              {
                  type: 'line',
                  smooth: 0.6,
                  symbol: 'none',
                  lineStyle: {
                      color: '#5470C6',
                      width: 5
                  },
                  markLine: {
                      symbol: ['none', 'none'],
                      label: {show: false},
                      data: [
                          {xAxis: 1},
                          {xAxis: 3},
                          {xAxis: 5},
                          {xAxis: 7}
                      ]
                  },
                  areaStyle: {},
                  data: [
                      ['2019-10-10', 200],
                      ['2019-10-11', 560],
                      ['2019-10-12', 750],
                      ['2019-10-13', 580],
                      ['2019-10-14', 250],
                      ['2019-10-15', 300],
                      ['2019-10-16', 450],
                      ['2019-10-17', 300],
                      ['2019-10-18', 100]
                  ]
              }
          ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
  }

  .bd {
    flex:1;
    font-size: 16px;
    display: flex;
    height: 100%;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
    padding: 0px 0px;
    list-style: none;
    li {
      font-size: 1em;
      padding: 1px 0;
      text-overflow:ellipsis;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
</style>
