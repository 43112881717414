<template>
  <div style="width: 100%; height: 100%; overflow: hidden; background-color: #0c1e5a;" v-resize:debounce="onResize" :class="{ fullscreen: isFullScreen }">
    <div
      style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;"
      :style="{transform: scaleStyle}">
      <div class="head">
        <div class="title">
          <span>生产监控</span>
        </div>

        <div class="operation-bar">
          <!-- <el-button type="text" @click="toggleVolume">
            <i v-if="isVolumeOn" class="fa fa-volume-up fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-volume-off fa-fw fa-lg icon"></i>
          </el-button> -->
          <el-button type="text" @click="load">
            <i class="fa fa-refresh fa-fw fa-lg icon"></i>
          </el-button>
          <el-button type="text" @click="toggleFullScreen">
            <i v-if="isFullScreen" class="fa fa-compress fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-expand fa-fw fa-lg icon"></i>
          </el-button>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; flex-grow:1; flex:1" class="body">
        <div class="box-container">
          <div v-if="!allChannelList || allChannelList.length === 0" style="flex: 1;">
            <h1 class="text-warning text-center">您尚未开通任何视频设备功能</h1>
          </div>
          <div class="box" style="width: 49%; height: 49%; margin: 5px; box-sizing: border-box;" :class="{'box-3-3': allChannelList.length > 4}" v-for="channel in allChannelList">
            <div class="bd" style="position: relative;">
              <video-channnel-player :channel="channel" :auto-play="true" :auto-pause="20" style="position: absolute; width: 100%; height: 100%;" ></video-channnel-player>
            </div>
            <div class="ft">
              <div class="text-center text-info">
                <div style="padding-top: 7px"><span>{{ channel.name }}</span></div>
                <!-- <el-popover
                  placement="top"
                  title="扫码可在手机上查看"
                  trigger="hover"
                  content="">
                  <img style="margin: 0 auto;" width="200" height="200" :src="qrcode(channel)"></img>
                  <el-button slot="reference" type="text"><i class="fa fa-qrcode"></i> 手机上查看</el-button>
                </el-popover> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'
import videoChannnelPlayer from '@/components/VideoChannelPlayer'
// import EZUIPlayer from '@/lib/ezuikit.js'
// import Hls from 'hls.js'

export default {
  directives: {
    resize
  },
  components: {
    videoChannnelPlayer
  },
  data () {
    return {
      scaleStyle: '',
      isFullScreen: false,
      allChannelList: []
    }
  },
  methods: {
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
      // 移动端不做处理
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) return

      // PC端尝试启用浏览器全屏模式
      let element = document.documentElement
      if (this.isFullScreen) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          // 对 Chrome 特殊处理，
          // 参数 Element.ALLOW_KEYBOARD_INPUT 使全屏状态中可以键盘输入。
          if (window.navigator.userAgent.toUpperCase().indexOf('CHROME') >= 0) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
          } else {
            // Safari 浏览器中，如果方法内有参数，则 Fullscreen 功能不可用。
            element.webkitRequestFullScreen()
          }
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    load () {
      // eslint-disable-next-line
      this.allChannelList = JSON.parse('[{"id":1,"hls":"https://hls01open.ys7.com/openlive/e55f1d9e65a245ed93739ff662e4969e.m3u8","name":"果园1"},{"id":3,"hls":"https://hls01open.ys7.com/openlive/c19c155ff80e450fad55868c5807f78d.m3u8","name":"果园2"},{"id":4,"hls":"https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8","name":"果园3"},{"id":5,"hls":"https://hls01open.ys7.com/openlive/1c56b8399ce14bd4b73d831fc27b717c.m3u8","name":"果园4"}]')
    },
    qrcode (channel) {
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(channel.hls)}`
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import "src/style/variables";

.box-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
}

.list-text {
  color: white;
}

.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  height: 500px;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.validate-statistics {
  .label {
    line-height: 2;
    color: #fff;
    font-size: 18px;
  }
  .value {
    font-size: 24px;
  }
}

.alarm-list {
  color: #fff;
  ul {
    padding: 5px 20px;
    list-style: none;
    li {
      font-size: 1em;
      padding: 10px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}
</style>