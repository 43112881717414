<template>
  <div class="box">
    <div class="hd">商户列表</div>
    <div class="bd">
      <el-table class="table-dark" :data="tableData" style="width: 100%; height: 100%">
        <el-table-column prop="date" label="品牌名称" width="120"></el-table-column>
        <el-table-column prop="time" label="地址" width="90"></el-table-column>
        <el-table-column prop="title" label="注册时间"></el-table-column>
      </el-table>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  name: 'RightChart3',
  data () {
    return {
      tableData: [{
        date: '袋鼠',
        time: '温州',
        title: '2020-07-01',
        done: '已处理'
      }, {
        date: '鳄鱼',
        time: '温州',
        title: '2020-07-01',
        done: '已处理'
      }, {
        date: '沙迪克',
        time: '温州',
        title: '2020-07-01',
        done: '已处理'
      }, {
        date: '牛白伦',
        time: '温州',
        title: '2020-07-01',
        done: '已处理'
      }]
    }
  },
  methods: {
    load() {
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .comments {
    text-align: left;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    span {
      color:#096dd9;
      font-size: 35px;
      font-weight: bold;
    }
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  // ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  // }
}
</style>
