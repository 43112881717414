<template>
  <div class="box">
    <div class="hd">商家销售额</div>

    <div class="bd">
      <div id='codeGenerate' style="height: 100%; width:100%"/>
      
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
import * as echarts from 'echarts'
export default {
  name: 'RightChart3',
  data () {
    return {
      tableData: [{
        date: '2020-04-03',
        time: '08：20',
        title: '原材料供给翻倍',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '原材料供给翻倍',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '原材料供给翻倍',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '原材料供给翻倍',
        done: '已处理'
      }]
    }
  },
  methods: {
    load() {
      this.drawCodeGenerateBrand('codeGenerate')
    },
    drawCodeGenerateBrand (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        grid:{
          x:55,
          y:15,
          x2:5,
          y2:25,
          borderWidth:1
        },
        xAxis: {
          type: 'category',
          data: ['袋鼠', '鳄鱼', '沙迪克', '纽百伦', '其他'],
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        series: [{
          data: [212, 200, 130, 170, 90],
          type: 'bar',
          color: ['#25C6FC']
        }]
      }
      myChart.setOption(option, true)
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }

  .comments {
    text-align: left;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    span {
      color:#096dd9;
      font-size: 35px;
      font-weight: bold;
    }
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  // ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  // }
}
</style>
