import axios from 'axios'
import qs from 'qs'
import store from '../util/store'
import bus from '../util/bus'

const API_URL = 'https://api.hudsonpower.cn/'
// const API_URL = 'https://api-dev.hudsonpower.cn'
// const API_URL = 'http://localhost:9002/'

let createApiAxios = function () {
  let myAxios = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    headers: {
      'X-Token': store.token
    }
  })

  // 请求拦截器，根据配置增加merchantOpenId参数
  myAxios.interceptors.request.use(function (config) {
    if (config.withMerchantOpenId) {
      let params = config.params || {}
      params.merchantOpenId = store.merchantInfo.merchantOpenId
      config.params = params
    }
    if (config.formData) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      config.data = qs.stringify(config.data)
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  // 响应拦截器，根据错误消息，在bus上发起响应事件
  myAxios.interceptors.response.use(function (response) {
    if (response.status === 200 && response.data) {
      let data = response.data
      if (data && data.resCode === '0') {
        return Promise.resolve(response.data)
      } else {
        bus.$emit('apiError', data)
        console.log(`接口错误: ${data.resDesc}`)
        return Promise.reject(new Error(`接口错误: ${data.resDesc}`))
      }
    } else {
      bus.$emit('networkError', `网络错误: ${response.status}`)
      console.log(`网络错误: ${response.status}`)
      return Promise.reject(new Error(`网络错误: ${response.status}`))
    }
  }, function (error) {
    bus.$emit('networkError', `网络错误: ${error}`)
    console.log(`网络错误: ${error}`)
    return Promise.reject(error)
  })
  return myAxios
}

let apiAxios = createApiAxios()

// 监听setToken, setMerchantInfo 事件，更新axios实例
bus.$on('setToken', (token) => {
  apiAxios = createApiAxios()
})
bus.$on('setMerchantInfo', (token) => {
  apiAxios = createApiAxios()
})

// 手机号码、验证码登录
export const mobileVerifyLogin = (params) => {
  return apiAxios.post('user/mobileVerifyLogin', {}, {
    params: params
  })
}

// 手机号码、验证码登录
export const sendMobileVerify = (params) => {
  return apiAxios.post('user/sendMobileVerify', {}, {
    params: params
  })
}

// 退出登录
export const logout = () => {
  return apiAxios.get('user/logout', {})
}

// 退出登录(同步)
export const logoutSync = () => {
  let xhr = new XMLHttpRequest()
  xhr.open('get', `${API_URL}user/logout`, false)
  xhr.setRequestHeader('X-Token', store.token)
  xhr.send()
}

// 查询我的用户信息
export const userInfo = (params) => {
  // demo
  if ('demo'.length === 4) {
    return Promise.resolve(
      JSON.parse('{"resCode":"0","result":{"id":1,"openId":"kusZfUl1EalD9znRIP5D5Q","name":"智慧小欣","mobile":"15900429439","avatar":"","isPlatformManager":0,"status":0,"createTime":1527265697000,"statusDesc":"正常","mobileValid":true},"resDesc":"ok"}')
    )
  }
  return apiAxios.get('user/info', {})
}
