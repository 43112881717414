<template>
  <div style="width: 100%; height: 100%; overflow: hidden; background-color: #0c1e5a;" v-resize:debounce="onResize" :class="{ fullscreen: isFullScreen }">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      
      <div class="head">
        <div class="title">
          <span>区块链电子通证奖励</span>
        </div>

        <div class="operation-bar">
          <!-- <el-button type="text" @click="toggleVolume">
            <i v-if="isVolumeOn" class="fa fa-volume-up fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-volume-off fa-fw fa-lg icon"></i>
          </el-button> -->
          <el-button type="text" @click="load">
            <i class="fa fa-refresh fa-fw fa-lg icon"></i>
          </el-button>
          <el-button type="text" @click="toggleFullScreen">
            <i v-if="isFullScreen" class="fa fa-compress fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-expand fa-fw fa-lg icon"></i>
          </el-button>
        </div>
      </div>
      <div class="body">
          <!-- middle -->
          <div style="flex: 4; width: 100px; display:flex; flex-direction: column;">
              <div style="height: 110px; display: flex;">
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4 class="text-success">总交易数</h4></div>
                      <div class="bd"><div class="value">1640</div></div>
                  </div>
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4 class="text-success">总钱包数</h4></div>
                      <div class="bd"><div class="value">30</div></div>
                  </div>
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4>总代币数</h4></div>
                      <div class="bd"><div class="value">1200</div></div>
                  </div>
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4>等值金额</h4></div>
                      <div class="bd">
                      <div class="value">20000</div>
                      </div>
                  </div>
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4>已发放代币</h4></div>
                      <div class="bd">
                      <div class="value">1000</div>
                      </div>
                  </div>
                  <div class="statistic box" style="flex: 1; margin: 10px;">
                      <div class="hd"><h4 class="text-warning">发放率</h4></div>
                      <div class="bd">
                      <div class="value">83%</div>
                      </div>
                  </div>
              </div>
              <div style="display:flex; flex-direction: row; height: 500px">
                  <div class="statistic box" style="flex:1; margin: 5px;">
                      <div class="hd"><h4 class="text-danger">钱包交易事件区块</h4></div>
                      <div class="bd">
                          <el-table :data="blocks" size="mini" class="table-dark" style="height: 100%">
                              <el-table-column prop="no" label="交易编号" width="80"/>
                              <el-table-column prop="to" label="钱包编号" width="125"/>
                              <el-table-column prop="event" label="金额" width="50"/>
                              <el-table-column prop="createTime" label="交易时间"/>
                              <el-table-column prop="phone" label="查看详情" width="80">
                                  <template slot-scope="scope">
                                      <el-button type="primary" icon="el-icon-edit" disabled style="margin: 5px" size="mini"></el-button>
                                      {{scope.row.phone}}
                                  </template>
                              </el-table-column>
                          </el-table>
                      </div>
                  </div>
                  <div class="statistic box" style="flex:2; margin: 5px;">    
                    <div class="bd" style="justify-content: center">
                        <el-tabs class="" v-model="tabActive" tab-position="left" type="border-card" style="width: 100%; height: 100%; overflow: hidden;">
                            <el-tab-pane label="钱包详情" name="first">
                                <!--<div style="font-size: 25px; color: #737373; margin: 25px; font-weight: bold; text-align: center">区块编号:&#32;<span style="font-size: 35px; color: #000000">858793</span></div>-->
                                <div>
                                    <div class="block-info-text-inline">钱包地址: &#32;</div>
                                    <span>
                                        <el-tooltip class="item" effect="dark" content="0x7a3e891bea993ef56e136dae86bc2a7319e97b26e687b474d59014755c3ece80" placement="bottom">
                                            <el-button type="text" style="font-size:25px; margin-right: 20px">0xbde...09d28</el-button>
                                        </el-tooltip>
                                    </span>
                                </div>
                                <div>
                                    <div class="block-info-text-inline">现有代币数: &#32;</div>
                                    <div style="font-size:25px; margin-right: 20px; display: inline; color: black">200</div>
                                    <div class="block-info-text-inline">总代币数: &#32;</div>
                                    <div style="font-size:25px; margin-right: 20px; display: inline; color: black">200</div>                                               
                                </div>
                                <div>
                                    <div class="block-info-text-inline">钱包创建时间: &#32;</div>
                                    <div style="font-size:25px; margin-right: 20px; display: inline; color: black">2020-06-18 09:10:50</div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                  </div>
              </div>
              <div class="offline-list box" style="flex: 1; display:flex; flex-direction: column; margin: 10px">
                  <div class="hd"><h4>代币支付数</h4></div>
                  <div class="bd">
                    <el-table :data="allCompanies" size="mini" class="table-dark" style="height: 100%">
                      <el-table-column prop="no" label="公司编号" width="150"/>
                      <el-table-column prop="name" label="名称" width="100"/>
                      <el-table-column prop="address" label="地址" width="175"/>
                      <el-table-column prop="createTime" label="上线时间"/>
                    </el-table>
                  </div>
              </div>
          </div>
          <div style="flex:1; width: 100px; display:flex; flex-direction: column;">
              <div class="offline-list box" style="flex: 1; height: 100px; margin: 10px;">
                  <div class="hd"><h4>可用主节点</h4></div>
                  <div class="bd">
                      <ul><li v-for="item in todoList" class="clearfix">
                          <span class="text-warning">{{item.name}}</span>
                          <span class="text-info" style="float:right;">{{item.typeDesc}}</span><br/>
                      </li></ul>
                  </div>
              </div>
              <div class="offline-list box" style="flex: 2; height: 100px; margin: 10px;">
                  <div class="hd"><h4>区块节点列表</h4></div>
                  <div class="bd">
                      <el-table :data="allPeers" size="mini" class="table-dark" style="height: 100%">
                          <el-table-column prop="no" label="节点编号" width="125"/>
                          <el-table-column prop="status" label="状态" width="80"/>
                          <el-table-column prop="createTime" label="上线时间"/>
                      </el-table>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import resize from 'vue-resize-directive'
import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    let videoChannel1 = {
      'hls': 'https://hls01open.ys7.com/openlive/0c2b7ed44b614652bb819e838e680dae.m3u8'
    }
    let videoChannel2 = {
      'hls': 'https://hls01open.ys7.com/openlive/4025cb74f943476a8aef96fdb6c130ce.m3u8'
    }
    let videoChannel3 = {
      'hls': 'https://alhlsgw.lechange.com/LCO/4D05CDAPAG1F078/0/0/20190522165111/dev_20190522165111_7s75nhk7n60fz42a.m3u8?proto=https'
    }
    let videoChannel4 = {
      'hls': 'https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8'
    }
    return {
      videoChannel1: videoChannel1,
      videoChannel2: videoChannel2,
      videoChannel3: videoChannel3,
      videoChannel4: videoChannel4,
      scaleStyle: 'scale(1, 1)',
      isFullScreen: false,
      todoList: [{
          name: "节点A",
          typeDesc: "exp.hudsonpower.cn:8546"
      }, {
          name: "节点B",
          typeDesc: "exp.hudsonpower34.cn:8546"
      }],
      url: "https://img.36krcdn.com/20200408/v2_61689c8a2f7f42d8a20a4d78cb49a1bb_img_000",
      srcList: [
        "https://img.36krcdn.com/20200408/v2_61689c8a2f7f42d8a20a4d78cb49a1bb_img_000"
      ],
      blocks: [{
        no: '858793',
        event: '20',
        from: '0x233...a8242',
        to: '0xd5a...a8648',
        createTime: '2020-06-18'
      }, {
        no: '858792',
        event: '20',
        from: '0xd5a...a8648',
        to: '0x233...a8242',
        createTime: '2020-06-18'
      }], 
      allPeers: [{
          no: "HJ1023",
          status: "在线",
          createTime: "2020-06-18 9:00",
      },{
          no: "TESA30",
          status: "在线",
          createTime: "2020-06-18 9:30",

      }],
      tabActive: 'first'
    }
  },
  methods: {
    load() {

    },
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
      // 移动端不做处理
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) return

      // PC端尝试启用浏览器全屏模式
      let element = document.documentElement
      if (this.isFullScreen) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          // 对 Chrome 特殊处理，
          // 参数 Element.ALLOW_KEYBOARD_INPUT 使全屏状态中可以键盘输入。
          if (window.navigator.userAgent.toUpperCase().indexOf('CHROME') >= 0) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
          } else {
            // Safari 浏览器中，如果方法内有参数，则 Fullscreen 功能不可用。
            element.webkitRequestFullScreen()
          }
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    drawBarChart(id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        textStyle: {
          color: "#fff"
        },
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        grid: {
          left: 50,
          right: 50,
          top: 20,
          bottom: 20
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: ['A901', 'A902', 'A904', 'A934', 'B954', 'A994', 'C094']
        },
        series: [
            {
                name: '2011年',
                type: 'bar',
                barWidth: 25, 
                barGap: 5,
                data: [10, 12, 20, 33, 39, 45, 50]
            }
        ]
      };
      myChart.setOption(option);
    }
  },
  mounted () {
    // this.drawLineChart("carStatics")
    // this.drawDynamicBarChart("parkingEvent")
  }
}
</script>
</script>
<style lang='scss' scoped>
@import "src/style/variables";
.block-info-text-inline {
    font-size:18px; 
    color: #909399; 
    display: inline;
    line-height: 70px
}
.block-info-text {
    font-size:18px; 
    color: #909399
}

.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  height: 500px;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1.2em;
      padding: 3px 0;
      a {
        color: #fff;
      }
    }
  }
}

.offline-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1.2em;
      padding: 3px 0;
      border-bottom: dashed 1px #999;
      a {
        color: #eee;
      }
    }
  }
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 24px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
::v-deep .anchorBL {
  opacity: .2;
}

.el-tabs.dark {
  box-sizing: border-box;
  border: 0;
  background-color: transparent;
  ::v-deep .el-tabs__header {
    min-width: 180px;
  }
  ::v-deep .el-tabls__content {
    padding: 0;
  }
  ::v-deep .el-tabs__header {
    background-color: transparent;
    ::v-deep .el-tabs__item.is-active {
      background-color: rgba(255, 255, 255, .2);
    }
  }
}
</style>


