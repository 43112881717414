<template>
  <div style="width: 100%; height: 100%; overflow: hidden; background-color: #0c1e5a;" v-resize:debounce="onResize" :class="{ fullscreen: isFullScreen }">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      <div class = "body" style="display: flex; flex-direction: row; flex-grow:1; flex:2">
        <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
          <div class="hd"><h4>来源查看</h4></div>
          <div class="bd" style="position: relative;">
            <el-table class="table-dark" :data="sourceList" style="position: abusolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%" >
                    <!-- <el-table-column type="index" width="40" :fixed="true">
                    </el-table-column> -->
              <el-table-column prop="message" label="内容"></el-table-column>
              <el-table-column label="时间" width="100">
                <template slot-scope="item">{{item.row.createTime | date("MM-DD HH:mm")}}</template>
              </el-table-column>
              <el-table-column width="150" label="操作">
                <template slot-scope="item"><el-button type="text" @click="productionDetail(item.row)">进入档案室</el-button></template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:3; margin: 5px">
          <div class="head">
            <div class="title">
              <span><div>{{headerName}}</div></span>
            </div>

            <div class="operation-bar">
              <!-- <el-button type="text" @click="toggleVolume">
                <i v-if="isVolumeOn" class="fa fa-volume-up fa-fw fa-lg icon"></i>
                <i v-else class="fa fa-volume-off fa-fw fa-lg icon"></i>
              </el-button> -->
              <el-button type="text" @click="load">
                <i class="fa fa-refresh fa-fw fa-lg icon"></i>
              </el-button>
              <el-button type="text" @click="toggleFullScreen">
                <i v-if="isFullScreen" class="fa fa-compress fa-fw fa-lg icon"></i>
                <i v-else class="fa fa-expand fa-fw fa-lg icon"></i>
              </el-button>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:2">
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2;">
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin: 5px">
                <div class="hd"><h4>{{videoName}}</h4></div>
                <div class="bd" style="display: block;">
                  <!--<iframe src="//player.bilibili.com/player.html?aid=285231383&bvid=BV1Yf4y1U7ip&cid=177044490&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="width: 100%; height: 600px; max-width: 100%；align:center; padding:20px 0; border:none"> </iframe>-->
                  <div id = "video">
                    <video-channnel-player v-if="videoShow" :channel="videoChannel1" :auto-play="true" :auto-pause="20" style="width: 100%; height: 400px"></video-channnel-player>
                  </div>
                  
                </div>
              </div>
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin: 5px">
                <div class="hd"><h4>公司简介</h4></div>
                <div class="bd" style="display: block;">
                  <h3 style="color: white; margin: 10px">{{companyIntro}}</h3>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:1;">
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
                <div class="hd"><h4>生产视频列表</h4></div>
                <div class="bd" style="position: relative;">
                    <el-table class="table-dark" :data="videoList" style="position: abusolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%" >
                        <!-- <el-table-column type="index" width="40" :fixed="true">
                        </el-table-column> -->
                        <el-table-column prop="message" label="内容"></el-table-column>
                        <el-table-column label="时间" width="100">
                            <template slot-scope="item">{{item.row.createTime | date("MM-DD HH:mm")}}</template>
                        </el-table-column>
                        <el-table-column width="150" label="操作">
                          <template slot-scope="item">
                            <el-button type="text" @click="watchVideo(item.row)">
                              查看视频
                            </el-button>
                          </template>
                        </el-table-column>
                    </el-table>
                </div>
              </div>
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
                <div class="hd"><h4>生产环境信息</h4></div>
                <div class="bd" style="position: relative;">
                    <el-table class="table-dark" :data="environment" style="position: abusolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%" >
                        <el-table-column prop="type" label="类型" width="100"></el-table-column>
                        <el-table-column prop="message" label="内容"></el-table-column>
                        <el-table-column prop="reportedBy" label="上报者" width="150"></el-table-column>
                        <el-table-column label="时间" width="100">
                            <template slot-scope="item">{{item.row.createTime | date("MM-DD HH:mm")}}</template>
                        </el-table-column>
                    </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */
import resize from 'vue-resize-directive'
import * as echarts from 'echarts'
import videoChannnelPlayer from '@/components/VideoChannelPlayer'

export default {
  components: {
    videoChannnelPlayer
  },
  directives: {
    resize
  },
  data () {
    let videoChannel1 = {
      'hls': 'https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8',
    }
    return {
        isFullScreen: false,
        activeName: 'first',
        input: '',
        scaleStyle: '',
        videoList: [{
          message: '车间视频',
          createTime: '07/01 07:14',
          videosrc: 'https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8'
        }],
        videoName: '车间视频',
        videoChannel1: videoChannel1,
        videoShow: true,
        headerName: '原产地xxxx车间档案室',
        environment: [{
          type: '温度传感',
          message: '25度',
          reportedBy: '自动上传',
          createTime: '07-01 12:01'
        }, {
          type: '湿度',
          message: '50%',
          reportedBy: '自动上传',
          createTime: '07-01 12:01'
        }],
        sourceList: [{
            message: '原产地xxxx车间',
            createTime: '07/01 07:14',
            videoList: [{
              message: '车间视频',
              createTime: '07/01 07:14',
              videosrc: 'https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8'
            }],
            environment: [{
              type: '温度传感',
              message: '25度',
              reportedBy: '自动上传',
              createTime: '07-01 12:01'
            }, {
              type: '湿度',
              message: '50%',
              reportedBy: '自动上传',
              createTime: '07-01 12:01'
            }],
            intro: '该车间位于青岛市崂山区华楼山风景区。果树生长于高山区域，远离生活区，无扬尘、无污染，以清凉甘甜的崂山水浇灌，盛产的果实绝对称得上绿色水果。该园主要生产樱桃和山杏，每年5月中旬的北宅樱桃节都会吸引大批的游客前来品尝，因所处的地理位置，和良好天然条件，所产的果子甘甜口感好，并含有众多的对人体有益的微量元素。欢迎广大游客和市民前来品尝。该地区风光优美，自然条件保护比较完整，在你品尝美味的果实之际还能让自己身处美丽的大自然之中。'
        }, {
            message: 'xxx加工厂包装',
            createTime: '07/05 07:14',
            //video: 'https://hls01open.ys7.com/openlive/ec6b81ebae1a4a7a903a7a3453b43d9d.m3u8',
            intro: '宏辉果蔬股份有限公司（股票代码：603336，简称：宏辉果蔬）成立于1992年，是在原汕头经济特区宏辉食品有限公司整体变更设立而成，于2016年11月24日在上交所成功挂牌，总部位于广东省汕头市。公司是一家集果蔬产品的种植管理、采后收购、产地预冷、冷冻仓储、预选分级、加工包装、冷链配送于一体的专业农产品服务商，主要产品包括约46个品类的水果以及约43个品类的蔬菜，品种涵盖全国各地及进口名优果蔬。目前公司分别在山东烟台、上海、广东广州、广东汕头、福建诏安、天津、江西赣州和香港设立了8家全资子公司, 合作的种植基地已覆盖山东、广东、福建、新疆、云南、江西、陕西等农业优势产区，针对果蔬产品具有季节性和区域性的特点，通过国内果蔬主产区的加工配送基地的布局和相互调配，以及冷链物流服务，有效解决果蔬供需之间的需求。',
            videoList: [{
              message: '加工视频一',
              createTime: '07/01 07:14',
              videosrc: 'https://hls01open.ys7.com/openlive/ec6b81ebae1a4a7a903a7a3453b43d9d.m3u8'
            },{
              message: '加工视频二',
              createTime: '07/01 07:14',
              videosrc: 'https://hls01open.ys7.com/openlive/434a5ca4ba864dd0b2b8d620968c2182.m3u8'
            }],
            environment: [{
              type: '温度传感',
              message: '25度',
              reportedBy: '自动上传',
              createTime: '07-01 12:01'
            }, {
              type: '质量检测',
              message: '合格',
              reportedBy: '自动',
              createTime: '07-01 12:01'
            }]
        }],
        companyIntro: '该车间位于青岛市崂山区华楼山风景区。果树生长于高山区域，远离生活区，无扬尘、无污染，以清凉甘甜的崂山水浇灌，盛产的果实绝对称得上绿色水果。该园主要生产樱桃和山杏，每年5月中旬的北宅樱桃节都会吸引大批的游客前来品尝，因所处的地理位置，和良好天然条件，所产的果子甘甜口感好，并含有众多的对人体有益的微量元素。欢迎广大游客和市民前来品尝。该地区风光优美，自然条件保护比较完整，在你品尝美味的果实之际还能让自己身处美丽的大自然之中。'
    }
  },
  methods: {
    load() {
      
    },
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
      // 移动端不做处理
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) return

      // PC端尝试启用浏览器全屏模式
      let element = document.documentElement
      if (this.isFullScreen) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          // 对 Chrome 特殊处理，
          // 参数 Element.ALLOW_KEYBOARD_INPUT 使全屏状态中可以键盘输入。
          if (window.navigator.userAgent.toUpperCase().indexOf('CHROME') >= 0) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
          } else {
            // Safari 浏览器中，如果方法内有参数，则 Fullscreen 功能不可用。
            element.webkitRequestFullScreen()
          }
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    init () {
      
    },
    productionDetail(item) {
      // console.log(item.video)
      let videoChannel2 = {
        'hls': item.videoList[0].videosrc
      }
      this.videoShow = false
      setTimeout(() =>{
        // alert("xxx")
        this.videoChannel1 = videoChannel2
        this.videoShow = true
        this.videoName = item.videoList[0].message
        this.headerName = item.message + '档案室'
        this.companyIntro = item.intro
        this.videoList = item.videoList
        this.environment = item.environment
      }, 500);      
    },
    watchVideo(item) {
      let videoChannel2 = {
        'hls': item.videosrc
      }
      this.videoShow = false
      setTimeout(() =>{
        // alert("xxx")
        this.videoName = item.message
        this.videoChannel1 = videoChannel2
        this.videoShow = true
      }, 500)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
@import "src/style/variables";

.head {
  box-sizing: border-box;
  margin-top: 5px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  height: 500px;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}
.list-text {
  color: white;
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1em;
      padding: 3px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

.operation-bar {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 36px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
::v-deep .anchorBL {
  opacity: .2;
}

.el-carousel__item h3 {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 40px;
  }

  // .el-carousel__item:nth-child(2n) {
  //    background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //    background-color: #d3dce6;
  // }

</style>