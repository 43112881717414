import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Aliance from '@/components/Aliance'
import Label from '@/components/Label'
import Rewards from '@/components/Rewards'
import Trace from '@/components/Trace'
//import Dashboard from '@/components/Dashboard'
import Dashboard from '@/components/Dashboard2/index'
// import Frame from '@/components/Frame'
import EShop from '@/components/EShop'
import Monitor from '@/components/Monitor'

Vue.use(Router)

let router = new Router({
  // mode: 'history',
  routes: [{
    path: '/',
    redirect: '/login'
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/Dashboard',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/Dashboard',
      component: Dashboard,
      name: '首页',
      menuShow: true
    }]
  }, {
    path: '/Monitor',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-video-camera fa-fw', // 图标样式class
    children: [{
      path: '/Monitor',
      component: Monitor,
      name: '生产监控',
      menuShow: true
    }]
  }, {
    path: '/Trace',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/Trace',
      component: Trace,
      name: '区块链智网溯源',
      menuShow: true
    }]
  }, {
    path: '/AntiFakeLabel',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/AntiFakeLabel',
      component: Label,
      name: '区块链防伪商标',
      menuShow: true
    }]
  }, {
    path: '/eShop',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/eShop',
      component: EShop,
      // props: {src: 'http://xinjiang.mall.hudsonpower.cn/'},
      name: '区块链电子商城',
      menuShow: true
    }]
  }, {
    path: '/Rewards',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/Rewards',
      component: Rewards,
      name: '区块链货币奖励',
      menuShow: true
    }]
  }, {
    path: '/Aliance',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/Aliance',
      component: Aliance,
      name: '区块链联盟',
      menuShow: true
    }]
  }, {
    path: '/*', // otherwise redirect to dashboard
    redirect: '/dashboard'
  }]
})

export default router
