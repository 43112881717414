<template>
  <div style="width: 100%; height: 100%; overflow: hidden; background-color: #0c1e5a;" v-resize:debounce="onResize" :class="{ fullscreen: isFullScreen }">
    <div
      style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;"
      :style="{transform: scaleStyle}">
      <div class="head">
        <div class="title">
          <span>区块链防伪商标</span>
        </div>

        <div class="operation-bar">
          <!-- <el-button type="text" @click="toggleVolume">
            <i v-if="isVolumeOn" class="fa fa-volume-up fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-volume-off fa-fw fa-lg icon"></i>
          </el-button> -->
          <el-button type="text" @click="load">
            <i class="fa fa-refresh fa-fw fa-lg icon"></i>
          </el-button>
          <el-button type="text" @click="toggleFullScreen">
            <i v-if="isFullScreen" class="fa fa-compress fa-fw fa-lg icon"></i>
            <i v-else class="fa fa-expand fa-fw fa-lg icon"></i>
          </el-button>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; flex-grow:1; flex:1" class="body">
        <!-- col-1 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:1;">

          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>最近24小时验证码校验</h4></div>
              <div class="bd validate-statistics">
                <el-row>
                  <el-col :span="8">
                    <div class="text-center label">校验请求</div>
                    <div class="text-center text-primary value">810</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-center label">校验通过</div>
                    <div class="text-center text-success value">792</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-center label">校验未通过</div>
                    <div class="text-center text-danger value">18</div>
                  </el-col>
                </el-row>
                <ul>
                  <li class="list-text">沙迪克黑玫瑰葡萄干 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克鹰嘴豆 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">哈密瓜 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克鹰嘴豆 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克黑玫瑰葡萄干 <span style="display: inline-block;" class="text-danger">未校验通过</span></li>
                  <li class="list-text">沙迪克库尔勒香梨 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克库尔勒香梨 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克鹰嘴豆 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">哈密瓜 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">沙迪克黑玫瑰葡萄干 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                  <li class="list-text">哈密瓜 <span style="display: inline-block;" class="text-danger">未校验通过</span></li>
                  <li class="list-text">沙迪克库尔勒香梨 <span style="display: inline-block;" class="text-primary">校验通过</span></li>
                </ul>
              </div>
            </div>
          </div>

          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>查询统计</h4></div>
              <div class="bd">
                <div style="padding: 20px 40px;">
                  <svg width="300px" height="172px" viewBox="0 0 300 209" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Desktop-HD" transform="translate(-1058.000000, -138.000000)">
                              <g id="查询成功率" transform="translate(1012.000000, 90.000000)">
                                  <g id="编组-4" transform="translate(46.793692, 43.000000)">
                                      <polyline id="路径-9" stroke="#979797" points="0 27.6666667 0 213 307.206308 213"></polyline>
                                      <polygon id="路径-10" stroke="#979797" fill="#25C6FC" points="0 213 0 131 70.8289493 111 139.720004 131 246.706308 85 290.206308 70 290.206308 213"></polygon>
                                      <text id="查询成功率" font-family="PingFangSC-Regular, PingFang SC" font-size="24" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="52.2063077" y="25">查询成功率</tspan>
                                      </text>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>

                <div style="padding: 20px 40px;">
                  <svg width="300px" height="190px" viewBox="0 0 300 235" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Desktop-HD" transform="translate(-1058.000000, -421.000000)">
                              <g id="业主满意度" transform="translate(1012.000000, 90.000000)">
                                  <g id="编组-5" transform="translate(46.793692, 326.000000)">
                                      <text id="APP" font-family="PingFangSC-Regular, PingFang SC" font-size="24" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="52.2063077" y="25">用户反馈</tspan>
                                      </text>
                                      <polyline id="路径-7" stroke="#979797" points="0 34 0 239 307.206308 239"></polyline>
                                      <rect id="矩形" stroke="#979797" fill="#7ED321" x="0.206307743" y="87" width="100" height="23" rx="10"></rect>
                                      <rect id="矩形备份" stroke="#979797" fill="#25C6FC" x="0.206307743" y="130" width="150" height="23" rx="10"></rect>
                                      <rect id="矩形备份-2" stroke="#979797" fill="#50E3C2" x="0.206307743" y="173" width="220" height="23" rx="10"></rect>
                                      <rect id="矩形备份-3" stroke="#979797" fill="#F8E71C" x="0.206307743" y="216" width="180" height="23" rx="10"></rect>
                                      <text id="网络异常" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="122.206308" y="101">网络异常</tspan>
                                      </text>
                                      <text id="数据加载问题" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="178.206308" y="145">数据加载问题</tspan>
                                      </text>
                                      <text id="积分相关问题" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="234.206308" y="188">积分相关问题</tspan>
                                      </text>
                                      <text id="其他" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="192.206308" y="228">其他</tspan>
                                      </text>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <!-- col-2 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:2;">
          <div style="display: flex; flex-direction: row; flex-grow:2; flex:2; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>验证码查询实时地图</h4></div>
              <div class="bd" style="display: flex; flex-direction: column;">
                <!-- <div style="height: 400px;"> -->
                  <!--chart
                    :options="mapChartOptions"
                    ref="mapChart"
                    style="width: 100%; height: 100%; border-radius: 50px;"
                    auto-resize
                  /-->
                <!-- </div> -->
                <div id="allmap" style="width:100%; height:100%;"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- col-3 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:2;">
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>防伪码生成统计</h4></div>
              <div class="bd">
                <div id="codeGenerate" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="alarm-list box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>防伪码生成商标</h4></div>
              <div class="bd">
                <div id="codeGenerateBrand" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>防伪码验证统计</h4></div>
              <div class="bd">
                <div id="codeValidate" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>防伪码验证商标</h4></div>
              <div class="bd">
                <div id="codeValidateBrand" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import resize from 'vue-resize-directive'
import echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    // 地图配置
    let mapChartOptions = {
      // title: {
      //   text: '智慧用电、消防和生产安全 监控云平台',
      //   top: 10,
      //   left: 'center',
      //   padding: [20, 100],
      //   backgroundColor: 'rgba(0, 0, 0, 0.7)',
      //   textStyle: {
      //     fontSize: 32,
      //     color: '$--color-primary',
      //     fontWeight: 800
      //   }
      // },
      tooltip: {
        trigger: 'item',
        formatter: function (val) {
          return val.value.name
        }
      },
      dataset: {
        source: [
          {longitude: 120.5, latitude: 31, status: 20},
          {longitude: 118.5, latitude: 28, status: 20},
          {longitude: 119.5, latitude: 29, status: 20},
          {longitude: 115.5, latitude: 32, status: 20},
          {longitude: 116.5, latitude: 39, status: 20},
          {longitude: 123, latitude: 42, status: 20},
          {longitude: 117, latitude: 33, status: 20}
        ]
      },
      bmap: {
        center: [120.713752, 27.925003],
        zoom: 15,
        roam: true,
        mapStyle: {
          // style: 'dark',
          styleJson: [
            {
              'featureType': 'all',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'all',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#000000',
                'weight': '0.1'
              }
            },
            {
              'featureType': 'land',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0a1429'
              }
            },
            {
              'featureType': 'building',
              'elementType': 'geometry',
              'stylers': {
                'color': '#2b2b2b'
              }
            },
            {
              'featureType': 'highway',
              'elementType': 'all',
              'stylers': {
                'lightness': -42,
                'saturation': -91
              }
            },
            {
              'featureType': 'arterial',
              'elementType': 'geometry',
              'stylers': {
                'lightness': -77,
                'saturation': -94
              }
            },
            {
              'featureType': 'green',
              'elementType': 'geometry',
              'stylers': {
                'color': '#1b1b1b'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0d1a36'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'continent',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            }
          ]
        },
      },
      series: {
        id: 'powerMapScatter',
        name: '电表',
        type: 'scatter',
        coordinateSystem: 'bmap',
        encode: {
          lng: 'longitude',
          lat: 'latitude'
        },
        symbolSize: function (value, params) {
          if (!params.value) return 10
          if (params.value.status === 0) return 10
          else if (params.value.status === 10) return 15
          else if (params.value.status === 20) return 15
          return 10
        },
        label: {
          normal: {
            formatter: '{@name}',
            position: 'right',
            color: '#003300',
            show: false
          },
          emphasis: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            color: function (val) {
              if (val.value.status === 0) return '#94d573'
              else if (val.value.status === 10) return '#ffd25d'
              else if (val.value.status === 20) return '#ff6b50'
              return '#CCCCCC'
            }
          }
        }
      }
    }
    return {
      scaleStyle: '',
      isFullScreen: false,
      guanJiaList: [
        { name: '白羊', head: 'https://res.hudsonpower.cn/static/head_01.jpg', value: 5 },
        { name: '红苹果', head: 'https://res.hudsonpower.cn/static/head_02.jpg', value: 4.5 },
        { name: '青柠', head: 'https://res.hudsonpower.cn/static/head_03.jpg', value: 4 },
        { name: '青芒果', head: 'https://res.hudsonpower.cn/static/head_04.jpg', value: 3.5 },
        { name: '黑凤梨', head: 'https://res.hudsonpower.cn/static/head_05.jpg', value: 3 },
        { name: '红椒', head: 'https://res.hudsonpower.cn/static/head_06.jpg', value: 3 }
      ],
      mapChartOptions: mapChartOptions,
      powerAlarmList: [
        {name: 'A区1-3电表', codeDesc: '过温报警', remark: '65度', typeDesc: '智能电表', createTime: new Date().getTime() - 200 * 1000},
        {name: 'B区1-3电表', codeDesc: '电流过大报警', remark: '38A', typeDesc: '智能电表', createTime: new Date().getTime() - 700 * 1000},
        {name: 'C区2-3电表', codeDesc: '电压过低报警', remark: '199V', typeDesc: '智能电表', createTime: new Date().getTime() - 8000 * 1000}
      ],
      waterAlarmList: [
        {name: 'A区1-3水表', codeDesc: '过温报警', remark: '45度', typeDesc: '智能水表', createTime: new Date().getTime() - 400 * 1000},
        {name: 'B区1-3消防栓', codeDesc: '水压过低报警', remark: '0.05MPa', typeDesc: '智能消防栓', createTime: new Date().getTime() - 1300 * 1000}
      ]
    }
  },
  methods: {
    load () {
      var map = new BMapGL.Map("allmap");    // 创建Map实例
      map.centerAndZoom(new BMapGL.Point(120.713752, 27.925003), 19);  // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      map.setHeading(64.5);
      map.setTilt(75);
    },
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
      // 移动端不做处理
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) return

      // PC端尝试启用浏览器全屏模式
      let element = document.documentElement
      if (this.isFullScreen) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          // 对 Chrome 特殊处理，
          // 参数 Element.ALLOW_KEYBOARD_INPUT 使全屏状态中可以键盘输入。
          if (window.navigator.userAgent.toUpperCase().indexOf('CHROME') >= 0) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
          } else {
            // Safari 浏览器中，如果方法内有参数，则 Fullscreen 功能不可用。
            element.webkitRequestFullScreen()
          }
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    drawCodeGenerate (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        backgroundColor: '',
        textStyle: {
          color: '#fff'
        },
        color: ['#25C6FC', '#67c23a', '#FFBD10', '#FF401D'],
        title: {
          text: '防伪码生成量',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['防伪码生成'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 3000)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '数量',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '防伪码生成',
            type: 'line',
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(Math.round(Math.random() * 50) + 50)
              }
              return res
            })()
          }
        ]
      }

      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        data0.shift()
        data0.push(Math.round(Math.random() * 500) + 1000)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        myChart.setOption(option)
      }, 3000)
    },
    drawCodeValidate (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        backgroundColor: '',
        textStyle: {
          color: '#fff'
        },
        color: ['#50E3C2', '#25C6FC', '#67c23a', '#FFBD10', '#FF401D'],
        title: {
          text: '防伪码校验',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['防伪码校验'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 3000)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '数量',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '防伪码校验',
            type: 'line',
            data: (function () {
              var res = []
              var len = 0
              while (len < 10) {
                res.push(Math.random() * 20 + 20)
                len++
              }
              return res
            })()
          }
        ]
      }

      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        data0.shift()
        data0.push(Math.round(Math.random() * 20) + 20)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        myChart.setOption(option)
      }, 3000)
    },
    drawCodeGenerateBrand (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        xAxis: {
          type: 'category',
          data: ['袋鼠', '鳄鱼', '沙迪克', '纽百伦', '其他'],
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        series: [{
          data: [212, 200, 130, 170, 90],
          type: 'bar',
          color: ['#25C6FC']
        }]
      }
      myChart.setOption(option, true)
    },
    drawCodeValidateBrand (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        xAxis: {
          type: 'category',
          data: ['袋鼠', '鳄鱼', '沙迪克', '纽百伦', '其他'],
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#21abfa',
              fontSize: '16'
            }
          }
        },
        series: [{
          data: [123, 200, 150, 70, 80],
          type: 'bar',
          color: ['#50E3C2']
        }]
      }
      myChart.setOption(option, true)
    }
  },
  mounted () {
    this.load()
    this.drawCodeGenerate('codeGenerate')
    this.drawCodeValidate('codeValidate')
    this.drawCodeGenerateBrand('codeGenerateBrand')
    this.drawCodeValidateBrand('codeValidateBrand')
  }
}
</script>


<style lang='scss' scoped>
@import "src/style/variables";
.list-text {
  color: white;
}

.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  height: 500px;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.validate-statistics {
  .label {
    line-height: 2;
    color: #fff;
    font-size: 18px;
  }
  .value {
    font-size: 24px;
  }
}

.alarm-list {
  color: #fff;
  ul {
    padding: 5px 20px;
    list-style: none;
    li {
      font-size: 1em;
      padding: 10px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

</style>