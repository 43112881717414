<template>
  <div class="box">
    <!-- <div class="hd">新商业生态</div> -->
    <div class="bd">
      <chart
        :options="mapChartOptions"
        ref="mapChart1"
        style="width: 100%; height: 100%; border-radius: 50px;"
        auto-resize
      />
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
import * as echarts from 'echarts'
export default {
  name: 'MidChart1',
  data () {
    let mapChartOptions = {
      // title: {
      //   text: '智慧用电、消防和生产安全 监控云平台',
      //   top: 10,
      //   left: 'center',
      //   padding: [20, 100],
      //   backgroundColor: 'rgba(0, 0, 0, 0.7)',
      //   textStyle: {
      //     fontSize: 32,
      //     color: '$--color-primary',
      //     fontWeight: 800
      //   }
      // },
      tooltip: {
        trigger: 'item',
        formatter: function (val) {
          return val.value.name
        }
      },
      dataset: {
        source: [
          {longitude: 120.5, latitude: 31, status: 20},
          {longitude: 118.5, latitude: 28, status: 20},
          {longitude: 119.5, latitude: 29, status: 20},
          {longitude: 115.5, latitude: 32, status: 20},
          {longitude: 116.5, latitude: 39, status: 20},
          {longitude: 123, latitude: 42, status: 20},
          {longitude: 117, latitude: 33, status: 20}
        ]
      },
      bmap: {
        center: [110, 18],
        zoom: 5,
        roam: true,
        mapStyle: {
          // style: 'dark',
          styleJson: [
            {
              'featureType': 'all',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'all',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#000000',
                'weight': '0.1'
              }
            },
            {
              'featureType': 'land',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0a1429'
              }
            },
            {
              'featureType': 'building',
              'elementType': 'geometry',
              'stylers': {
                'color': '#2b2b2b'
              }
            },
            {
              'featureType': 'highway',
              'elementType': 'all',
              'stylers': {
                'lightness': -42,
                'saturation': -91
              }
            },
            {
              'featureType': 'arterial',
              'elementType': 'geometry',
              'stylers': {
                'lightness': -77,
                'saturation': -94
              }
            },
            {
              'featureType': 'green',
              'elementType': 'geometry',
              'stylers': {
                'color': '#1b1b1b'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0d1a36'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'continent',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            }
          ]
        }
      },
      series: [{
        name: '商品溯源',
        type: 'scatter',
        coordinateSystem: 'bmap',
        encode: {
          lng: 'longitude',
          lat: 'latitude'
        },
        symbolSize: function (value, params) {
          if (!params.value) return 10
          if (params.value.status === 0) return 10
          else if (params.value.status === 10) return 15
          else if (params.value.status === 20) return 15
          return 10
        },
        label: {
          normal: {
            formatter: '{@name}',
            position: 'right',
            color: '#003300',
            show: false
          },
          emphasis: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            color: function (val) {
              if (val.value.status === 0) return '#94d573'
              else if (val.value.status === 10) return '#ffd25d'
              else if (val.value.status === 20) return '#ff6b50'
              return '#CCCCCC'
            }
          }
        }
      }]
    }
    return {
      mapChartOptions: mapChartOptions,
    }
  },
  methods: {
    load() {
        this.drawMapChart()
    },
    drawMapChart() {
      var myChart = echarts.init(this.$refs['mapChart1'].getDom(), 'dark');
      myChart.setOption(this.mapChartOptions);
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.nbox {
  padding: 10px;
  h4 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h4.label {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #25C6FC;
  }
  h4.value {
    height: 50px;
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
  }
}


.number-bar {
  background-color: #0f1e3e;
  box-sizing: border-box;
  border: solid 1px #25C6FC;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 #25C6FC inset;
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}
</style>