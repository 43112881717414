<template>
  <div class="box">
    <div class="hd">产品溯源档案室</div>
    <div class="bd">
      <el-table class="table-dark" :data="tableData" style="width: 100%; height: 100%" height="100%">
        <el-table-column prop="date" label="日期" width="120"></el-table-column>
        <el-table-column prop="time" label="时间" width="90"></el-table-column>
        <el-table-column prop="title" label="内容"></el-table-column>
         <el-table-column width="150" label="操作">
            <template slot-scope="item"><el-button type="text" @click="productionDetail(item.row)">进入档案室</el-button></template>
          </el-table-column>
      </el-table>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
import * as echarts from 'echarts';

export default {
  name: 'MidChart2',
  data () {
    return {
      tableData: [{
        date: '2020-04-03',
        time: '08：20',
        title: '车间处理线1',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '车间处理线2',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '仓库A',
        done: '已处理'
      }, {
        date: '2020-04-03',
        time: '08：20',
        title: '仓库B',
        done: '已处理'
      }]
    }
  },
  methods: {
    load() {
      
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  ::v-deep tr, ::v-deep th {
    background-color: transparent;
  }
  ::v-deep .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  ::v-deep .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  ::v-deep .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  // ::v-deep .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  // }


  ::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.1);
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}


</style>