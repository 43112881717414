<template>
  <div v-if="!show" style="position: relative;">
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-sizing: border-box; width: 80px; height: 80px; border-left: solid #999 80px; border-top: solid transparent 40px; border-bottom: solid transparent 40px;"
      @click="show=true"></div>
  </div>
  <video v-else v-bind:id="pid" poster="" controls playsInline autoplay webkit-playsinline>
    <source :src="channel.hls" type="application/x-mpegURL" />
    <source :src="channel.rtmp" type="" />
  </video>
</template>
<script>
import {EZUIPlayer} from '@/lib/ezuikit.js'

export default {
  name: 'VideoChannelPlayer',
  props: {
    channel: {
      type: String,
      default: ''
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    autoPause: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let pid = 'vcp' + (((1 + Math.random()) * 0x1000000000) | 0).toString(16).substring(1)
    return {
      player: null,
      pid: pid,
      timer: 0,
      stopTimer: 0,
      show: this.autoPlay
    }
  },
  watch: {
    channel () {
      this.load()
    },
    show (newValue) {
      console.log(newValue)
      if (newValue) {
        this.show = true
        this.load()
      }
    }
  },
  methods: {
    load () {
      let delay = Math.ceil(Math.random() * 500)
      this.timer = setTimeout(() => {
        this.player = new EZUIPlayer(this.pid)
      }, delay)
      if (this.autoPause > 0) {
        this.stopTimer = setTimeout(() => {
          if (this.player) {
            this.player.pause()
          }
        }, this.autoPause * 1000)
      }
    }
  },
  mounted () {
    if (this.show) this.load()
  },
  beforeDestroy () {
    if (this.player) {
      this.player.stop()
    }
  },
  destroyed () {
    if (this.timer) window.clearTimeout(this.timer)
    if (this.stopTimer) window.clearTimeout(this.stopTimer)
  }
}
</script>
<style lang="scss" scoped>
</style>

