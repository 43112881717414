<template>
  <div class="box">
    <div style="height: 100px; width: 100%; display: flex;" class="number-bar">
      <div style="flex: 1;" class="nbox">
        <h4 class="label">总交易</h4>
        <h4 class="value">{{1640}}</h4>
      </div>
      <div style="flex: 1;" class="nbox">
        <h4 class="label">总钱包数</h4>
        <h4 class="value">10</h4>
      </div>
      <div style="flex: 1;" class="nbox">
        <h4 class="label">总代币数</h4>
        <h4 class="value">1200</h4>
      </div>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  name: 'LeftChart1',
  data () {
    return {}
  },
  methods: {
    load() {
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}

.display-board {
    display: flex;
    flex-direction: column;
    height: 100%;

    .item-row {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 100%;

        .card {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #1a53ff;
            padding: 10px;
            border-radius: 20px;
            color: white;
            margin: 10px;

            .header {
                font-size: 15px;
                font-weight: bold;
            }

            .content {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
}

.number-bar {
  background-color: #0f1e3e;
  box-sizing: border-box;
  border: solid 1px #25C6FC;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 #25C6FC inset;
}

.nbox {
  padding: 10px;
  h4 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h4.label {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #25C6FC;
  }
  h4.value {
    height: 50px;
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
  }
}
</style>
