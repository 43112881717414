<template>
  <div class="data-view">
    <full-screen-container class="full-screen-container" :min-width="1920">
      <dv-border-box-1 class="main-container" style="display: flex: align-items: stretch">
        <dv-border-box-9 class="body-column" style="flex: 2; height: auto;">
          <Left-Chart-1 style="height: 150px" />
          <Left-Chart-2 style="flex: 1;" />
          <Left-Chart-3 style="flex: 1;" />
          <Left-Chart-4 style="flex: 2;" />
        </dv-border-box-9>
        <dv-border-box-8 class="body-column" style="flex: 3; height: auto;">
          <Mid-Chart-1 style="flex: 1" />
          <Mid-Chart-2 style="height: 300px" />
          <!--Mid-Chart-3 style="flex: 1;" /-->
        </dv-border-box-8>
        <dv-border-box-9 class="body-column" style="flex: 2; height: auto;">
          <Right-Chart-1 style="flex: 1;" />
          <Right-Chart-2 style="flex: 1;" />
          <Right-Chart-3 style="flex: 1;" />
        </dv-border-box-9>
      </dv-border-box-1>
    </full-screen-container>
  </div>
</template>

<script>

import LeftChart1 from './LeftChart1'
import LeftChart2 from './LeftChart2'
import LeftChart3 from './LeftChart3'
import LeftChart4 from './LeftChart4'

import MidChart1 from './MidChart1'
import MidChart2 from './MidChart2'
// import MidChart3 from './MidChart3'

import RightChart1 from './RightChart1'
import RightChart2 from './RightChart2'
import RightChart3 from './RightChart3'
import FullScreenContainer from '@/components/common/FullScreenContainer.vue'

export default {
  name: 'DataView',
  components: {
    LeftChart1,
    LeftChart2,
    LeftChart3,
    LeftChart4,
    MidChart1,
    MidChart2,
    // MidChart3,
    RightChart1,
    RightChart2,
    RightChart3,
    FullScreenContainer
  },
  data () {
    return {
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
  }
}
</script>

<style lang="scss" scoped>
.data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .full-screen-container {
    // background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    // display: flex;
    flex-direction: column;
  }

  .main-container {
    height: 100%;
    background-color: #08153f;
    background-image: url('../../assets/images/bg.png');
    ::v-deep .border-box-content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
    }
    // box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .body-column {
    width: 33%;
    box-sizing: border-box;

    ::v-deep .border-box-content {
      flex-direction: column;
    }
  }
  ::v-deep .item {
    flex: 1;
  }

}

</style>
